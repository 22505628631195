<template>
  <gf-view title="Stock Breakdown">
    <div class="container">
      <el-table style="width: 100%; height: 100%" height="100%" header-cell-class-name="cell-header" cell-class-name="cell-style" :data = "stockLocations">
        <el-table-column label="Location" min-width="110px">
          <template slot-scope="slot">
            {{ slot.row.warehouse.name }}
          </template>
        </el-table-column>
        <el-table-column label="On Hand" min-width="100px">
          <template slot-scope="slot">
            {{ $numberFormat(slot.row.value) }}
          </template>
        </el-table-column>
        <el-table-column label="Available" min-width="100px">
          <template slot-scope="slot">
            {{ $numberFormat(slot.row.value - slot.row.commited) }}
          </template>
        </el-table-column>
        <el-table-column label="Commited In Sales Orders" min-width="160px">
          <template slot-scope="slot">
            {{ $numberFormat(slot.row.commited) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </gf-view>
</template>

<style lang="scss" scoped>
.container {
  padding: 0 20px;
  flex: 1;
}
</style>

<script>
import StockLocationService from '@/services/v1/StockLocation'

export default {
  data () {
    return {
      stockLocations: []
    }
  },
  methods: {
    async getStockLocations () {
      try {
        const productId = this.$route.params.id
        const variantId = this.$route.params.vid
        const slService = new StockLocationService(productId, variantId)

        const result = await slService.list()
        this.stockLocations = result.data.rows
      } catch (error) {
        this.$error500()
      }
    }
  },
  mounted () {
    this.$emit('update:active', 3)
    this.getStockLocations()
  }
}
</script>
